import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaEnvelope } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Header from './Header';
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const Profile = () => {
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get('https://jetwatchers.com/api/profile/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to fetch profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
            Profile
          </h2>
          {isLoading ? (
            <p className="text-text-light">Loading profile...</p>
          ) : error ? (
            <p className="text-error">{error}</p>
          ) : (
            <div className="bg-card p-6 rounded-xl shadow-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">First Name</label>
                  <div className="relative">
                    <p className="w-full px-4 py-2 bg-background text-white rounded-md pl-10">
                      {profile.first_name}
                    </p>
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">Last Name</label>
                  <div className="relative">
                    <p className="w-full px-4 py-2 bg-background text-white rounded-md pl-10">
                      {profile.last_name}
                    </p>
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">Email</label>
                  <div className="relative">
                    <p className="w-full px-4 py-2 bg-background text-white rounded-md pl-10">
                      {profile.email}
                    </p>
                    <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Profile;
