import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlus, FaTrash, FaThLarge, FaList, FaCheckCircle } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Header from './Header';

function Flyplan() {
  const [flyplans, setFlyplans] = useState([]);
  const [filteredFlyplans, setFilteredFlyplans] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [telegramChannels, setTelegramChannels] = useState([]);
  const [selectedTelegramChannels, setSelectedTelegramChannels] = useState([]);
  const [newFlyplan, setNewFlyplan] = useState({
    celebrity_name: '',
    tail_number: '',
    photo: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showFlySchedule, setShowFlySchedule] = useState(false);
  const [editingFlyplan, setEditingFlyplan] = useState(null);

  useEffect(() => {
    fetchFlyplans();
    fetchTelegramChannels();
  }, []);

  useEffect(() => {
    const results = flyplans.filter(flyplan =>
        flyplan.celebrity_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flyplan.tail_number.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFlyplans(results);
  }, [searchTerm, flyplans]);
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const fetchFlyplans = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://jetwatchers.com/api/flyplans/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setFlyplans(response.data);
    } catch (error) {
      console.error('Error fetching flyplans:', error);
      setError('Failed to fetch flyplans. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTelegramChannels = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://jetwatchers.com/api/telegram-setting/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setTelegramChannels(response.data);
    } catch (error) {
      console.error('Error fetching telegram channels:', error);
      setError('Failed to fetch telegram channels. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setNewFlyplan({ ...newFlyplan, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setNewFlyplan({ ...newFlyplan, photo: e.target.files[0] });
  };

  const handleTelegramChannelChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value); // Get values as strings
    setSelectedTelegramChannels(selectedOptions); // Set selected options in state
  };

  const handleEdit = (flyplan) => {
    setEditingFlyplan(flyplan);
    setNewFlyplan({
      celebrity_name: flyplan.celebrity_name,
      tail_number: flyplan.tail_number,
      photo: null,
    });
    setSelectedTelegramChannels(flyplan.telegram_channels.map(channel => channel.id.toString()));
    setIsFormVisible(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('celebrity_name', newFlyplan.celebrity_name);
    formData.append('tail_number', newFlyplan.tail_number);
    if (newFlyplan.photo) {
      formData.append('photo', newFlyplan.photo);
    }

    selectedTelegramChannels.forEach((channelId) => {
      formData.append('telegram_channels', channelId);
    });

    try {
      await axios.put(`https://jetwatchers.com/api/flyplans/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          id: editingFlyplan.id
        }
      });
      setNewFlyplan({ celebrity_name: '', tail_number: '', photo: null });
      setSelectedTelegramChannels([]);
      setEditingFlyplan(null);
      fetchFlyplans();
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error updating flyplan:', error);
      setError('Failed to update flyplan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('celebrity_name', newFlyplan.celebrity_name);
    formData.append('tail_number', newFlyplan.tail_number);
    if (newFlyplan.photo) {
      formData.append('photo', newFlyplan.photo);
    }

    selectedTelegramChannels.forEach((channelId) => {
      formData.append('telegram_channels', channelId);
    });

    try {
      await axios.post(`https://jetwatchers.com/api/flyplans/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setNewFlyplan({ celebrity_name: '', tail_number: '', photo: null });
      setSelectedTelegramChannels([]);
      fetchFlyplans();
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error creating flyplan:', error);
      setError('Failed to create flyplan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const handleDelete = async (id) => {
    setIsLoading(true);
    setError('');
    try {
      await axios.delete(`https://jetwatchers.com/api/flyplans/${id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      fetchFlyplans();
    } catch (error) {
      console.error('Error deleting flyplan:', error);
      setError('Failed to delete flyplan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div className="flex h-screen bg-background">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header />
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
            <motion.div
                className="flyplan-container"
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
                }}
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-white">Flyplans</h2>
                <div className="flex items-center space-x-4">
                  <input
                      type="text"
                      placeholder="Search by celebrity or tail number"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="px-4 py-2 bg-card text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  <motion.button
                      onClick={() => setShowFlySchedule(!showFlySchedule)}
                      className="px-4 py-2 bg-gradient-to-r from-primary to-secondary text-white rounded-md transition duration-300"
                  >
                    {showFlySchedule ? 'Hide Fly Schedule' : 'Show Fly Schedule'}
                  </motion.button>
                  <motion.button
                      onClick={() => {
                        setIsFormVisible(!isFormVisible);
                        setEditingFlyplan(null);
                        setNewFlyplan({ celebrity_name: '', tail_number: '', photo: null });
                        setSelectedTelegramChannels([]);
                      }}
                      className="px-4 py-2 bg-gradient-to-r from-primary to-secondary text-white rounded-md transition duration-300"
                  >
                    <FaPlus className="inline-block mr-2" /> {isFormVisible ? 'Cancel' : 'Add Flyplan'}
                  </motion.button>
                  <motion.button
                      onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                      className="p-2 bg-card text-white rounded-md transition duration-300"
                  >
                    {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                  </motion.button>
                </div>
              </div>
              {error && <div className="text-error mb-4">{error}</div>}
              <AnimatePresence>
                {isFormVisible && (
                    <motion.form
                        onSubmit={editingFlyplan ? handleUpdate : handleSubmit}
                        className="bg-card p-6 rounded-xl shadow-lg mb-6"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="celebrity_name" className="block text-sm font-medium text-gray-400 mb-1">
                            Celebrity Name
                          </label>
                          <input
                              type="text"
                              id="celebrity_name"
                              name="celebrity_name"
                              value={newFlyplan.celebrity_name}
                              onChange={handleInputChange}
                              placeholder="Enter celebrity name"
                              required
                              className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                          />
                        </div>
                        <div>
                          <label htmlFor="tail_number" className="block text-sm font-medium text-gray-400 mb-1">
                            Tail Number
                          </label>
                          <input
                              type="text"
                              id="tail_number"
                              name="tail_number"
                              value={newFlyplan.tail_number}
                              onChange={handleInputChange}
                              placeholder="Enter tail number"
                              required
                              className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="photo" className="block text-sm font-medium text-gray-400 mb-1">
                          Photo
                        </label>
                        <input
                            type="file"
                            id="photo"
                            onChange={handleFileChange}
                            className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                        />
                        {editingFlyplan && (
                            <p className="text-sm text-gray-400 mt-1">
                              Leave empty to keep the current photo
                            </p>
                        )}
                      </div>

                      <div className="mt-4">
                        <label htmlFor="telegram_channels" className="block text-sm font-medium text-gray-400 mb-1">
                          Select Telegram Channels
                        </label>
                        <select
                            id="telegram_channels"
                            name="telegram_channels"
                            multiple
                            value={selectedTelegramChannels}
                            onChange={handleTelegramChannelChange}
                            className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                        >
                          {telegramChannels.length > 0 ? (
                              telegramChannels.map((channel) => (
                                  <option key={channel.id} value={channel.id} className="bg-background text-white">
                                    {channel.name}
                                  </option>
                              ))
                          ) : (
                              <option value="" disabled>No channels available</option>
                          )}
                        </select>
                      </div>

                      <button
                          type="submit"
                          disabled={isLoading}
                          className="mt-6 w-full px-4 py-2 bg-gradient-to-r from-primary to-secondary text-white rounded-md hover:opacity-90 transition duration-300"
                      >
                        {isLoading ? 'Saving...' : editingFlyplan ? 'Update Flyplan' : 'Add Flyplan'}
                      </button>
                    </motion.form>
                )}
              </AnimatePresence>
              {isLoading ? (
                  <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
                  </div>
              ) : (
                  <AnimatePresence>
                    <motion.div
                        className={`grid ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-6`}
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                      {filteredFlyplans.map((flyplan) => (
                          <motion.div
                              key={flyplan.id}
                              className="bg-card rounded-xl shadow-lg overflow-hidden relative"
                              variants={itemVariants}
                              layout
                          >
                            <div className="h-64 bg-gray-800 flex items-center justify-center overflow-hidden">
                              <img
                                  src={flyplan.photo_url}
                                  alt={flyplan.celebrity_name}
                                  className="w-full h-full object-contain"
                              />
                            </div>
                            <div className="absolute top-2 right-2 bg-blue-500 rounded-full p-1">
                              <FaCheckCircle className="text-white text-lg" />
                            </div>
                            <div className="p-4">
                              <h3 className="text-xl font-semibold text-white mb-2">{flyplan.celebrity_name}</h3>
                              <p className="text-gray-400 mb-4"><strong>Tail Number:</strong> {flyplan.tail_number}</p>
                              <div className="flex space-x-2">
                                <motion.button
                                    onClick={() => handleEdit(flyplan)}
                                    className="flex-1 px-4 py-2 bg-primary text-white rounded-md transition duration-300"
                                >
                                  Update
                                </motion.button>
                                <motion.button
                                    onClick={() => handleDelete(flyplan.id)}
                                    className="flex-1 px-4 py-2 bg-error text-white rounded-md transition duration-300"
                                >
                                  <FaTrash className="inline-block mr-2" /> Delete
                                </motion.button>
                              </div>
                            </div>
                          </motion.div>
                      ))}
                    </motion.div>
                  </AnimatePresence>
              )}

            </motion.div>
          </main>
        </div>
      </div>
  );
}

export default Flyplan;

