import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaPlaneDeparture, FaSearch, FaCalendarAlt, FaBroadcastTower, FaPaperPlane, FaChevronDown, FaChevronUp, FaCog } from 'react-icons/fa';

const Sidebar = () => {
  const location = useLocation();
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

  const menuItems = [
    { path: '/dashboard', name: 'Dashboard', icon: <FaHome /> },
    { path: '/search', name: 'Search', icon: <FaSearch /> },
    { path: '/fly-schedule', name: 'Flight Schedules', icon: <FaCalendarAlt /> },
    { path: '/live', name: 'Live', icon: <FaBroadcastTower /> },
  ];

  const adminMenuItems = [
    { path: '/flyplans', name: 'Flightplans', icon: <FaPlaneDeparture /> },
    { path: '/telegram-settings', name: 'Telegram Settings', icon: <FaPaperPlane /> },
  ];

  return (
    <div className="w-64 bg-background h-full shadow-lg">
      <div className="p-6">
        <div className="flex items-center justify-center">
          <FaPlaneDeparture className="text-3xl text-primary mr-2" />
          <h2 className="text-xl font-bold bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">Jetwatcher</h2>
        </div>
      </div>
      <nav className="mt-6">
        <ul>
          {menuItems.map((item) => (
            <li key={item.path} className="mb-2">
              <Link
                to={item.path}
                className={`flex items-center px-6 py-3 text-text-dark ${
                  location.pathname === item.path ? 'text-white' : ''
                }`}
              >
                <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-primary to-secondary rounded-md mr-3">
                  {item.icon}
                </div>
                <span className="font-medium">{item.name}</span>
              </Link>
            </li>
          ))}
          <li className="mb-2">
            <button
              onClick={() => setAdminDropdownOpen(!adminDropdownOpen)}
              className="flex items-center px-6 py-3 text-text-dark w-full"
            >
              <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-primary to-secondary rounded-md mr-3">
                <FaCog />
              </div>
              <span className="font-medium">Admin</span>
              {adminDropdownOpen ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
            </button>
            {adminDropdownOpen && (
              <ul className="ml-8">
                {adminMenuItems.map((item) => (
                  <li key={item.path} className="mb-2">
                    <Link
                      to={item.path}
                      className={`flex items-center px-6 py-3 text-text-dark ${
                        location.pathname === item.path ? 'text-white' : ''
                      }`}
                    >
                      <div className="w-6 h-6 flex items-center justify-center bg-gradient-to-r from-primary to-secondary rounded-md mr-3">
                        {item.icon}
                      </div>
                      <span className="font-medium">{item.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
