import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function MapComponent({ originLat, originLon, destinationLat, destinationLon }) {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    console.log('MapComponent received coordinates:', { originLat, originLon, destinationLat, destinationLon });

    if (originLat === null || originLon === null || destinationLat === null || destinationLon === null) {
      console.error('Invalid coordinates:', { originLat, originLon, destinationLat, destinationLon });
      return;
    }

    // Clean up existing map instance
    if (mapInstanceRef.current) {
      mapInstanceRef.current.remove();
      mapInstanceRef.current = null;
    }

    // Create new map instance
    const map = L.map(mapRef.current).setView([0, 0], 2);
    mapInstanceRef.current = map;

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Create custom icons for origin and destination
    const originIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    const destinationIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    // Add markers for origin and destination
    const originMarker = L.marker([originLat, originLon], {icon: originIcon}).addTo(map);
    const destinationMarker = L.marker([destinationLat, destinationLon], {icon: destinationIcon}).addTo(map);

    // Add popups to markers
    originMarker.bindPopup("Origin").openPopup();
    destinationMarker.bindPopup("Destination").openPopup();

    const bounds = L.latLngBounds(
      [originLat, originLon],
      [destinationLat, destinationLon]
    );
    map.fitBounds(bounds, { padding: [50, 50] });

    const polyline = L.polyline(
      [[originLat, originLon], [destinationLat, destinationLon]],
      { color: 'blue', weight: 3, opacity: 0.7 }
    ).addTo(map);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, [originLat, originLon, destinationLat, destinationLon]);

  return <div ref={mapRef} style={{ height: '100%', width: '100%' }} />;
}

export default MapComponent;