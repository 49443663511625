import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Flyplan from './components/Flyplan';
import Search from './components/Search';
import Profile from './components/Profile';
import FlySchedule from './components/FlySchedule';
import Live from './components/Live';
import PrivateRoute from './components/PrivateRoute';
import TelegramSettings from './components/TelegramSetting';


function App() {
  return (
    <Router>
      <div className="App bg-background min-h-screen">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/tables" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/billing" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/flyplans" element={<PrivateRoute><Flyplan /></PrivateRoute>} />
          <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />
          <Route path="/fly-schedule" element={<PrivateRoute><FlySchedule /></PrivateRoute>} />
          <Route path="/live" element={<PrivateRoute><Live /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/telegram-settings" element={<PrivateRoute><TelegramSettings /></PrivateRoute>} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
