import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { FaPlane, FaClock, FaCheckCircle, FaExclamationTriangle, FaMapMarkedAlt } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Header from './Header';

const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Dashboard = () => {
  const [upcomingFlights, setUpcomingFlights] = useState([]);
  const [activeFlights, setActiveFlights] = useState([]);
  const [completedFlights, setCompletedFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFlights();
  }, []);

  const fetchFlights = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://jetwatchers.com/api/fly-schedule/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        params: {
          hours: 24, // Fetch flights for the last 24 hours to cover both upcoming and recently completed
        },
      });
      
      const now = new Date();
      const next12Hours = new Date(now.getTime() + 12 * 60 * 60 * 1000);
      const past12Hours = new Date(now.getTime() - 12 * 60 * 60 * 1000);
      
      // Filter for currently active flights
      const currentlyActive = response.data.filter(flight => {
        const depTime = new Date(flight.dep_time_est);
        const arrTime = new Date(flight.arr_time_est);
        return depTime <= now && arrTime >= now;
      });
      
      // Filter for upcoming flights in the next 12 hours
      const upcoming = response.data.filter(flight => {
        const depTime = new Date(flight.dep_time_est);
        return depTime >= now && depTime <= next12Hours;
      });
      
      // Filter for completed flights in the last 12 hours
      const completed = response.data.filter(flight => {
        const arrTime = new Date(flight.arr_time_est);
        return flight.status === 'COMPLETED' && arrTime >= past12Hours && arrTime <= now;
      });
      
      setActiveFlights(currentlyActive);
      setUpcomingFlights(upcoming);
      setCompletedFlights(completed);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching flights:', error);
      setError('Failed to load flight data. Please try again later.');
      setIsLoading(false);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'ACTIVE':
        return <FaPlane className="text-green-500" />;
      case 'PROPOSED':
        return <FaClock className="text-blue-500" />;
      case 'COMPLETED':
        return <FaCheckCircle className="text-purple-500" />;
      case 'DROPPED':
        return <FaExclamationTriangle className="text-yellow-500" />;
      default:
        return null;
    }
  };

  const calculateTimeRemaining = (depTime) => {
    const now = new Date();
    const departure = new Date(depTime);
    const timeRemaining = departure - now;

    if (timeRemaining <= 0) {
      return 'Departing now';
    }

    const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  const renderStatusCard = (status, flights) => {
    const statusColors = {
      ACTIVE: 'bg-gradient-to-r from-green-800 to-green-900',
      PROPOSED: 'bg-gradient-to-r from-blue-400 to-blue-600',
      COMPLETED: 'bg-gradient-to-r from-purple-400 to-purple-600',
      DROPPED: 'bg-gradient-to-r from-yellow-400 to-yellow-600',
    };

    return (
      <div className={`${statusColors[status]} p-6 rounded-xl shadow-lg min-h-[600px]`}>
        <h3 className="text-2xl font-bold mb-6 text-white flex items-center">
          {getStatusIcon(status)}
          <span className="ml-2">{status} ({flights.length})</span>
        </h3>
        {flights.length > 0 ? (
          <div className="space-y-6 max-h-[520px] overflow-y-auto pr-2">
            {flights.slice(0, 5).map((flight) => (
              <div key={flight.id} className="bg-white bg-opacity-5 backdrop-filter backdrop-blur-lg p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
                <div className="flex items-center mb-3">
                  {flight.photo && (
                    <img 
                      src={`https://jetwatchers.com/media/flyplan_photos/${flight.photo.split('/').pop()}`}
                      alt={flight.celebrity_name || 'Flight'} 
                      className="w-16 h-16 object-cover rounded-full mr-4 border-2 border-white" 
                    />
                  )}
                  <div>
                    <h4 className="text-xl font-semibold text-white">{flight.callsign}</h4>
                    {flight.celebrity_name && (
                      <p className="text-sm text-gray-200">Celebrity: {flight.celebrity_name}</p>
                    )}
                  </div>
                </div>
                <p className="text-sm text-gray-200 mb-1">
                  <span className="font-semibold">Origin:</span> {flight.origin_full !== 'Unknown, Unknown, Unknown' ? flight.origin_full : ''} ({flight.origin})
                </p>
                <p className="text-sm text-gray-200 mb-1">
                  <span className="font-semibold">Destination:</span> {flight.destination_full !== 'Unknown, Unknown, Unknown' ? flight.destination_full : ''} ({flight.destination})
                </p>
                <p className="text-sm text-gray-200 mb-1">Departure: {new Date(flight.dep_time_est).toLocaleString()}</p>
                {status === 'COMPLETED' && (
                  <p className="text-sm text-gray-200 mb-1">Arrival: {new Date(flight.arr_time_est).toLocaleString()}</p>
                )}
                {status === 'PROPOSED' && (
                  <p className="text-sm text-yellow-300 mt-2 font-semibold">
                    Takeoff in: {calculateTimeRemaining(flight.dep_time_est)}
                  </p>
                )}
                {status === 'ACTIVE' && (
                  <a
                    href={`https://globe.adsbexchange.com/?reg=${flight.reg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 inline-block text-green-300 hover:text-green-100 transition duration-300"
                    title="Show on Map"
                  >
                    <FaMapMarkedAlt size={24} />
                  </a>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-200 text-center">
            No {status.toLowerCase()} flights {status === 'ACTIVE' ? 'at the moment' : status === 'COMPLETED' ? 'in the last 12 hours' : 'in the next 12 hours'}.
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900 p-6">
          <h2 className="text-4xl font-bold mb-8 text-white bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
            Flight Dashboard
          </h2>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress />
            </div>
          ) : error ? (
            <p className="text-red-500 text-center text-xl">{error}</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {renderStatusCard('ACTIVE', activeFlights)}
              {renderStatusCard('PROPOSED', upcomingFlights)}
              {renderStatusCard('COMPLETED', completedFlights)}
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
