import React, { useState } from 'react';
import axios from 'axios';
import { FaSearch, FaPlane, FaSpinner } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Header from './Header';
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const Live = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSearchResult(null);

    try {
      const response = await axios.get(
        `https://jetwatchers.com/api/live-search/?search=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );
      setSearchResult(response.data[0]); // Assuming the API returns an array with one result
    } catch (error) {
      console.error('Error fetching live search data:', error);
      setError('An error occurred while fetching data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
            Live Flight Search
          </h2>
          <div className="bg-card p-6 rounded-xl shadow-lg mb-6">
            <form onSubmit={handleSearch} className="flex items-center space-x-4">
              <div className="flex-grow">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Enter registration or callsign"
                />
              </div>
              <button
                type="submit"
                className="px-6 py-2 bg-gradient-to-r from-primary to-secondary text-white rounded-md hover:opacity-90 transition duration-300 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : (
                  <FaSearch className="mr-2" />
                )}
                {isLoading ? 'Searching...' : 'Search'}
              </button>
            </form>
          </div>

          {error && (
            <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded-md mb-6">
              {error}
            </div>
          )}

          {searchResult && (
            <div className="bg-card rounded-xl shadow-lg p-6 animate-fadeIn">
              <h3 className="text-2xl font-semibold text-white mb-6 flex items-center">
                <FaPlane className="mr-3 text-primary" /> Active Flight
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Registration:</span> {searchResult.reg}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Callsign:</span> {searchResult.callsign}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Status:</span> {searchResult.status}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Aircraft Type:</span> {searchResult.icao_type}
                  </p>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Origin:</span> {searchResult.origin_full}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Destination:</span> {searchResult.destination_full}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Departure:</span> {new Date(searchResult.dep_time_est).toLocaleString()}
                  </p>
                  <p className="text-gray-300">
                    <span className="font-semibold text-primary">Arrival:</span> {new Date(searchResult.arr_time_est).toLocaleString()}
                  </p>
                </div>
              </div>
              <p className="text-gray-400 mt-6 text-sm">
                <span className="font-semibold">Last Updated:</span> {new Date(searchResult.updated).toLocaleString()}
              </p>
              <a
                href={`https://globe.adsbexchange.com/?reg=${searchResult.reg}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block mt-4 px-4 py-2 bg-primary text-white rounded-md hover:bg-opacity-90 transition duration-300"
              >
                View on ADS-B Exchange
              </a>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Live;
