import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar';
import Header from './Header';
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const icaoTypeOptions = {
  'All Jets': '',
  'Private Jets': 'GL,GA,CL,LJ,FA',
};

function Search() {
  const [searchParams, setSearchParams] = useState({
    reg: '',
    status: '',
    origin: '',
    destination: '',
    dep_time_est: '',
    arr_time_est: '',
    icao_type: '',
    callsign: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [, setTotalResults] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const handleInputChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleResultsPerPageChange = (e) => {
    setResultsPerPage(Number(e.target.value));
    if (hasSearched) {
      setCurrentPage(1);
      fetchResults(1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setHasSearched(true);
    await fetchResults(1);
  };

  const handleClear = () => {
    setSearchParams({
      reg: '',
      status: '',
      origin: '',
      destination: '',
      dep_time_est: '',
      arr_time_est: '',
      icao_type: '',
      callsign: '',
    });
    setHasSearched(false);
    setSearchResults([]);
    setTotalResults(0);
    setTotalPages(0);
  };

  const handleDateTimeClear = (field) => {
    setSearchParams(prev => ({ ...prev, [field]: '' }));
  };

  const fetchResults = async (page = currentPage) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`https://jetwatchers.com/api/search/`, {
        params: {
          ...searchParams,
          page: page,
          per_page: resultsPerPage,
          ordering: '-dep_time_est',  // Sort by departure time, newest first
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      console.log('Search API response:', response.data);

      setSearchResults(response.data.results);
      setTotalResults(response.data.count);
      setCurrentPage(page);
      setTotalPages(Math.ceil(response.data.count / resultsPerPage));
    } catch (err) {
      console.error('Error fetching search results:', err);
      setError(err.response?.data?.message || 'Error performing search. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchResults(newPage);
    }
  };

  return (
    <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
            Flight Search
          </h2>
          <form onSubmit={handleSubmit} className="bg-card p-6 rounded-xl shadow-lg mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor="reg" className="block text-sm font-medium text-gray-400 mb-1">Registration</label>
                <input
                  type="text"
                  id="reg"
                  name="reg"
                  value={searchParams.reg}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Enter registration"
                />
              </div>
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-400 mb-1">Status</label>
                <select
                  id="status"
                  name="status"
                  value={searchParams.status}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                >
                  <option value="">All</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="PROPOSED">PROPOSED</option>
                </select>
              </div>
              <div>
                <label htmlFor="origin" className="block text-sm font-medium text-gray-400 mb-1">Origin</label>
                <input
                  type="text"
                  id="origin"
                  name="origin"
                  value={searchParams.origin}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Enter origin"
                />
              </div>
              <div>
                <label htmlFor="destination" className="block text-sm font-medium text-gray-400 mb-1">Destination</label>
                <input
                  type="text"
                  id="destination"
                  name="destination"
                  value={searchParams.destination}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Enter destination"
                />
              </div>
              <div>
                <label htmlFor="dep_time_est" className="block text-sm font-medium text-gray-400 mb-1">Estimated Departure</label>
                <div className="relative">
                  <input
                    type="datetime-local"
                    id="dep_time_est"
                    name="dep_time_est"
                    value={searchParams.dep_time_est}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  {searchParams.dep_time_est && (
                    <button type="button" onClick={() => handleDateTimeClear('dep_time_est')} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white">
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="arr_time_est" className="block text-sm font-medium text-gray-400 mb-1">Estimated Arrival</label>
                <div className="relative">
                  <input
                    type="datetime-local"
                    id="arr_time_est"
                    name="arr_time_est"
                    value={searchParams.arr_time_est}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  {searchParams.arr_time_est && (
                    <button type="button" onClick={() => handleDateTimeClear('arr_time_est')} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white">
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="icao_type" className="block text-sm font-medium text-gray-400 mb-1">ICAO Type</label>
                <select
                  id="icao_type"
                  name="icao_type"
                  value={searchParams.icao_type}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                >
                  {Object.entries(icaoTypeOptions).map(([label, value]) => (
                    <option key={label} value={value}>{label}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="callsign" className="block text-sm font-medium text-gray-400 mb-1">Callsign</label>
                <input
                  type="text"
                  id="callsign"
                  name="callsign"
                  value={searchParams.callsign}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-background text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Enter callsign"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="px-6 py-2 bg-gradient-to-r from-primary to-secondary text-white rounded-md hover:opacity-90 transition duration-300 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Searching...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
                  </>
                )}
              </button>
              <button
                type="button"
                onClick={handleClear}
                className="px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 flex items-center justify-center"
              >
                <FontAwesomeIcon icon={faTimes} className="mr-2" /> Clear
              </button>
            </div>
          </form>

          {hasSearched && (
            <div className="bg-card p-6 rounded-xl shadow-lg mt-6">
              <h3 className="text-xl font-bold mb-4 text-white">Search Results</h3>
              {error && <p className="text-error mb-4">{error}</p>}
              {isLoading ? (
                <p className="text-gray-400"><FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading...</p>
              ) : searchResults.length > 0 ? (
                <>
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <label htmlFor="resultsPerPage" className="text-gray-400 mr-2">Results per page:</label>
                      <select
                        id="resultsPerPage"
                        value={resultsPerPage}
                        onChange={handleResultsPerPageChange}
                        className="bg-background text-white rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary"
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 mr-2 disabled:opacity-50"
                      >
                        Previous
                      </button>
                      <span className="text-gray-400">{`Page ${currentPage} of ${totalPages}`}</span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 ml-2 disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-700">
                          <th className="px-4 py-2 text-left text-gray-300">Registration</th>
                          <th className="px-4 py-2 text-left text-gray-300">Status</th>
                          <th className="px-4 py-2 text-left text-gray-300">Origin</th>
                          <th className="px-4 py-2 text-left text-gray-300">Destination</th>
                          <th className="px-4 py-2 text-left text-gray-300">Estimated Departure</th>
                          <th className="px-4 py-2 text-left text-gray-300">Estimated Arrival</th>
                          <th className="px-4 py-2 text-left text-gray-300">ICAO Type</th>
                          <th className="px-4 py-2 text-left text-gray-300">Callsign</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResults.map((result, index) => {
                          const depTimeEst = new Date(result.dep_time_est);
                          const now = new Date();
                          const timeDiff = depTimeEst - now;
                          const hoursDiff = timeDiff / (1000 * 60 * 60);
                          
                          let rowClass = index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900';
                          if (hoursDiff <= 2 && hoursDiff > 0) {
                            rowClass += ' bg-yellow-900';
                          } else if (hoursDiff <= 0 && hoursDiff > -2) {
                            rowClass += ' bg-green-900';
                          }

                          return (
                            <tr key={index} className={rowClass}>
                              <td className="px-4 py-2 text-white">{result.reg}</td>
                              <td className={`px-4 py-2 ${
                                result.status === 'ACTIVE' ? 'text-green-400' : 
                                result.status === 'CANCELLED' ? 'text-red-400' : 'text-yellow-400'
                              }`}>
                                {result.status}
                              </td>
                              <td className="px-4 py-2 text-gray-300">{result.origin}</td>
                              <td className="px-4 py-2 text-gray-300">{result.destination}</td>
                              <td className="px-4 py-2 text-gray-300">{result.dep_time_est}</td>
                              <td className="px-4 py-2 text-gray-300">{result.arr_time_est}</td>
                              <td className="px-4 py-2 text-gray-300">{result.icao_type}</td>
                              <td className="px-4 py-2 text-gray-300">{result.callsign}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <p className="text-gray-400">No results found.</p>
              )}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default Search;
