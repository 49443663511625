import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaKey, FaTelegramPlane, FaUser } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Header from './Header';

const TelegramSettings = () => {
    const [telegramData, setTelegramData] = useState({
        name: '',
        client_id: '',
        bot_token: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [telegramSettings, setTelegramSettings] = useState([]);
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [settingsError, setSettingsError] = useState('');

    useEffect(() => {
        fetchTelegramSettings();
    }, []);

    const fetchTelegramSettings = async () => {
        setIsLoadingSettings(true);
        setSettingsError('');
        try {
            const response = await axios.get('https://jetwatchers.com/api/telegram-setting/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            });
            console.log('API response:', response);
            if (Array.isArray(response.data)) {
                setTelegramSettings(response.data);
            } else if (typeof response.data === 'object' && response.data !== null) {
                setTelegramSettings([response.data]); // Wrap single object in array
            } else {
                setSettingsError('Invalid data received from server');
            }
        } catch (error) {
            console.error('Error fetching Telegram settings:', error);
            setSettingsError(`Failed to fetch Telegram settings: ${error.message}`);
        } finally {
            setIsLoadingSettings(false);
        }
    };

    const handleChange = (e) => {
        setTelegramData({
            ...telegramData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`https://jetwatchers.com/api/telegram-setting/`, telegramData, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response);
            setSuccess('Telegram settings saved successfully!');
            fetchTelegramSettings(); // Refresh the list after saving
            setTelegramData({ name: '', client_id: '', bot_token: '' }); // Clear the form
        } catch (error) {
            console.error('Error saving Telegram settings:', error);
            if (error.response && error.response.data) {
                const errorMessages = Object.entries(error.response.data)
                    .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
                    .join(' | ');
                setError(errorMessages);
            } else {
                setError('Failed to save Telegram settings. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this Telegram setting?')) {
            try {
                await axios.delete(`https://jetwatchers.com/api/telegram-setting/${id}/`, {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                });
                setSuccess('Telegram setting deleted successfully!');
                fetchTelegramSettings(); // Refresh the list after deleting
            } catch (error) {
                console.error('Error deleting Telegram setting:', error);
                if (error.response) {
                    switch (error.response.status) {
                        case 403:
                            setError('You do not have permission to delete this Telegram setting.');
                            break;
                        case 404:
                            setError('The Telegram setting you are trying to delete was not found.');
                            break;
                        default:
                            setError(`Failed to delete Telegram setting: ${error.response.data.detail || 'Unknown error'}`);
                    }
                } else if (error.request) {
                    setError('No response received from the server. Please check your internet connection.');
                } else {
                    setError('An unexpected error occurred. Please try again.');
                }
            }
        }
    };

    return (
        <div className="flex h-screen bg-background">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <Header />
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
                    <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
                        Telegram Settings
                    </h2>
                    <form onSubmit={handleSubmit} className="bg-card p-6 rounded-xl shadow-lg mb-8">
                        {error && <p className="text-error mb-4">{error}</p>}
                        {success && <p className="text-success mb-4">{success}</p>}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Name</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="name"
                                        value={telegramData.name}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 bg-background text-white rounded-md pl-10"
                                        placeholder="Enter a name for this Telegram setting"
                                        required
                                    />
                                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Telegram Chat ID</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="client_id"
                                        value={telegramData.client_id}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 bg-background text-white rounded-md pl-10"
                                        placeholder="Enter your Telegram Chat ID"
                                        required
                                    />
                                    <FaTelegramPlane className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Telegram Bot Token</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="bot_token"
                                        value={telegramData.bot_token}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 bg-background text-white rounded-md pl-10"
                                        placeholder="Enter your Telegram Bot Token"
                                        required
                                    />
                                    <FaKey className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <button
                                type="submit"
                                className={`px-6 py-2 rounded-md bg-primary text-white font-semibold transition ${
                                    isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-dark'
                                }`}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save Telegram Settings'}
                            </button>
                        </div>
                    </form>
                    
                    <h3 className="text-2xl font-bold mt-8 mb-4 text-white">Existing Telegram Settings</h3>
                    {isLoadingSettings ? (
                        <p className="text-gray-400">Loading Telegram settings...</p>
                    ) : settingsError ? (
                        <div>
                            <p className="text-error">{settingsError}</p>
                            <button 
                                onClick={fetchTelegramSettings} 
                                className="mt-2 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark"
                            >
                                Retry
                            </button>
                        </div>
                    ) : telegramSettings.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {telegramSettings.map((setting) => (
                                <div key={setting.id} className="bg-card p-4 rounded-xl shadow-lg">
                                    <h4 className="text-lg font-semibold text-white mb-2">{setting.name}</h4>
                                    <p className="text-gray-400 mb-2">Chat ID: {setting.client_id}</p>
                                    <button
                                        onClick={() => handleDelete(setting.id)}
                                        className="px-4 py-2 bg-error text-white rounded-md hover:bg-error-dark transition"
                                    >
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-400">No Telegram settings found.</p>
                    )}
                </main>
            </div>
        </div>
    );
};

export default TelegramSettings;
