import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaSignOutAlt, FaBell, FaUser } from 'react-icons/fa';
import axios from 'axios';
import moment from 'moment-timezone';
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const Header = () => {
  const navigate = useNavigate();
  const [userPicture, setUserPicture] = useState('');
  const [userName, setUserName] = useState('');
  const [currentTime, setCurrentTime] = useState({
    UK: '',
    LA: '',
    UTC: ''
  });

  useEffect(() => {
    const previousUser = JSON.parse(localStorage.getItem('previousUser'));
    if (previousUser) {
      if (previousUser.picture) {
        setUserPicture(previousUser.picture);
      }
      if (previousUser.name) {
        setUserName(previousUser.name);
      }
    }

    const timer = setInterval(() => {
      setCurrentTime({
        UK: moment().tz('Europe/London').format('HH:mm'),
        LA: moment().tz('America/Los_Angeles').format('HH:mm'),
        UTC: moment().utc().format('HH:mm')
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        navigate('/login');
        return;
      }
      await axios.post(`https://jetwatchers.com/api/logout/`, {}, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('previousUser');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      if (error.response && error.response.status === 403) {
        console.error('User not authenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('previousUser');
        navigate('/login');
      }
    }
  };

  return (
    <header className="bg-background shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex space-x-4">
            <div className="text-white">
              <span className="font-bold">UK:</span> {currentTime.UK}
            </div>
            <div className="text-white">
              <span className="font-bold">LA:</span> {currentTime.LA}
            </div>
            <div className="text-white">
              <span className="font-bold">UTC:</span> {currentTime.UTC}
            </div>
          </div>
          <nav className="flex items-center space-x-4">
            <button className="text-text-dark hover:text-white transition-colors duration-200">
              <FaBell className="text-xl" />
            </button>
            <Link to="/profile" className="flex items-center">
              {userPicture ? (
                <img 
                  src={userPicture} 
                  alt="User" 
                  className="w-8 h-8 rounded-full object-cover mr-2"
                />
              ) : (
                <FaUser className="text-xl text-text-dark mr-2" />
              )}
              <span className="text-white">{userName}</span>
            </Link>
            <button
              onClick={handleLogout}
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-primary to-secondary rounded-md hover:opacity-90 transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              <FaSignOutAlt className="mr-2" />
              <span>Log out</span>
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
