import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
console.log(ApiBaseUrl);

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isApproved] = useState(false);
    const [previousUser, setPreviousUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkPreviousLogin = () => {
            const previousUserData = localStorage.getItem('previousUser');
            if (previousUserData) {
                setPreviousUser(JSON.parse(previousUserData));
            }
        };

        checkPreviousLogin();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post(`https://jetwatchers.com/api/login/`, { username, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('previousUser', JSON.stringify({ name: response.data.name, email: response.data.email }));
            navigate('/dashboard');
        } catch (err) {
            console.error(err);
            if (err.response) {
                setError(err.response.data.non_field_errors?.[0] || 'Login failed. Please check your credentials.');
            } else if (err.request) {
                setError('Unable to connect to the server. Please check your internet connection and try again.');
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log('Google login success. Token Response:', tokenResponse);
            try {
                console.log('Sending access token to server:', tokenResponse.access_token);
                const result = await axios.post('https://jetwatchers.com/api/google-login/', {
                    access_token: tokenResponse.access_token,
                });
                console.log('Server response:', result.data);
                if (result.data.token) {
                    localStorage.setItem('token', result.data.token);
                    localStorage.setItem('previousUser', JSON.stringify({
                        name: result.data.name,
                        email: result.data.email,
                        picture: result.data.picture // Store the profile picture URL
                    }));
                    console.log('Login successful, navigating to dashboard');
                    navigate('/dashboard');
                } else {
                    console.error('Login failed: No token in server response');
                    setError('Login failed. Please try again.');
                }
            } catch (err) {
                console.error('Google login error:', err);
                if (err.response) {
                    console.error('Error response:', err.response.data);
                    setError(err.response.data.error || 'An unexpected error occurred. Please try again later.');
                } else if (err.request) {
                    console.error('No response received:', err.request);
                    setError('Unable to connect to the server. Please check your internet connection and try again.');
                } else {
                    console.error('Error setting up request:', err.message);
                    setError('An unexpected error occurred. Please try again later.');
                }
            }
        },
        onError: (error) => {
            console.error('Google login failed. Error object:', error);
            setError('Google login failed. Please try again.');
        },
    });

    if (isApproved) {
        return <div>Redirecting to dashboard...</div>;
    }

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <div className="flex justify-center items-center h-screen bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1608023136037-626dad6c6188?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80')"}}>
                <div className="bg-card bg-opacity-80 p-12 rounded-xl shadow-card w-full max-w-md backdrop-filter backdrop-blur-lg">
                    <h2 className="text-3xl font-bold mb-2 text-text-light">Welcome to Jetwatchers</h2>
                    {error && <p className="text-error mb-4">{error}</p>}
                    {previousUser && (
                        <div className="mb-6">
                            <p className="text-text-light mb-2">Welcome back, {previousUser.name}!</p>
                            <button onClick={handleGoogleLogin} className="w-full py-3 bg-gradient-to-r from-primary to-secondary text-white font-semibold rounded-md transition duration-300 flex items-center justify-center hover:opacity-90">
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-6 h-6 mr-2" />
                                Quick Login as {previousUser.email}
                            </button>
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                placeholder="Enter your username"
                                className="w-full p-3 bg-background bg-opacity-50 rounded-md text-text-light placeholder-text-dark focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Enter your password"
                                className="w-full p-3 bg-background bg-opacity-50 rounded-md text-text-light placeholder-text-dark focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <button type="submit" className="w-full py-3 bg-gradient-to-r from-primary to-secondary text-white font-semibold rounded-md transition duration-300 hover:opacity-90">
                            Login
                        </button>
                    </form>
                    {!previousUser && (
                        <div className="mt-6">
                            <button onClick={handleGoogleLogin} className="w-full py-3 bg-gradient-to-r from-primary to-secondary text-white font-semibold rounded-md transition duration-300 flex items-center justify-center hover:opacity-90">
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-6 h-6 mr-2" />
                                Sign in with Google
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </GoogleOAuthProvider>
    );
}

export default Login;
